import React from "react";
import classNames from "classnames";
import { SplitCard } from "@molecules";
import { Container } from "@atoms";

const CaseStudyGrid = ({ caseStudies, className: _className }) => {
  return (
    <section className={classNames("py-16 md:py-32", _className)}>
      <Container>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          {caseStudies?.map(c => {
            return <SplitCard key={c.uid} {...c} />;
          })}
        </div>
      </Container>
    </section>
  );
};

CaseStudyGrid.defaultProps = {};

export default CaseStudyGrid;
